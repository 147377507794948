import { template as template_d03b48792fd14e5cb0a912ded5ed6154 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_d03b48792fd14e5cb0a912ded5ed6154(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
