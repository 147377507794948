import { template as template_1ce994d670c5409f84f7e2fcfa344c49 } from "@ember/template-compiler";
const FKText = template_1ce994d670c5409f84f7e2fcfa344c49(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
