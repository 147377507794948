import { template as template_0fa916aa9c50413d9ca6aea3eeaf4a27 } from "@ember/template-compiler";
const FKLabel = template_0fa916aa9c50413d9ca6aea3eeaf4a27(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
